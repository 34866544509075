/* Reset styles */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700&family=Roboto+Mono:wght@400;500;600&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box !important;
	text-decoration: none;
	list-style: none;
	font-family: "Inter", sans-serif;
	scroll-behavior: smooth;
}
::-webkit-scrollbar {
	display: none !important;
}
.ant-input::placeholder {
	color: #333333e4 !important;
}

/* Global styles */
body {
	font-size: 16px;
	/* line-height: 1.5; */
	background-color: #f7f7f7;
	color: #0a0a0a;
	width: 100%;
}
.ant-select-selection-placeholder {
	color: #333333d7 !important;
}
::placeholder {
	color: #333333ba !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
div {
	display: block;
}
/* Component-specific styles */
.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	/* padding: 0 20px; */
}

.button {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

input {
	display: block;
	width: 100%;
	padding: 8px 10px;
	font-size: 14px;
	border: none;
	border-radius: 5px;
	outline: none;
}
